/**
 * Video
 *
 * This component requires jQuery and Magnific popup to function.
 * You can require it on the page using <div data-component="video"></div>
 */

import $ from 'jquery';
import 'magnific-popup';

$(function () {
    $('[data-component="video"]').each(function () {
        var $this = $(this);

        $('.js-video-toggle', $this).magnificPopup(
            {
                type: 'iframe',
                mainClass: 'mfp-fade',
                removalDelay: 160,
                preloader: false,
                fixedContentPos: false,
                iframe: {
                    patterns: {
                        youtu: {
                            index: 'youtu.be',
                            id: function (url) {
                                // Capture everything after the hostname, excluding possible querystrings.
                                var m = url.match(/^.+youtu.be\/([^?]+)/);

                                if (null !== m) {
                                    return m[1];
                                }

                                return null;
                            },
                            // Use the captured video ID in an embed URL.
                            src: '//www.youtube.com/embed/%id%?autoplay=1&rel=0'
                        }
                    }
                }
            }
        );
    });
});