/**
 * Carousel
 *
 * This component requires jQuery and Slick to function.
 * You can require it on the page using <div data-component="carousel"></div>
 */

import $ from 'jquery';
import 'slick-carousel';

$(function () {
    $('[data-component="carousel"]').each(function () {
        var $holder = $(this);

        let variableWidth = $holder.attr('data-variable-width');

        if ( variableWidth === null ) {
            variableWidth = true;
        } else {
            variableWidth = variableWidth === 'true';
        }

        let slidesToShow = $holder.attr('data-slides-to-show');

        if ( slidesToShow === undefined ) {
            slidesToShow = 3;
        }

        let slidesToShowMobile = $holder.attr('data-slides-to-show-mobile');

        if ( slidesToShowMobile === undefined ) {
            slidesToShowMobile = 3;
        }

        let centerMode = $holder.attr('data-center-mode');

        if ( centerMode === undefined ) {
            centerMode = false;
        } else {
            centerMode = centerMode === 'true';
        }

        $holder.slick(
            {
                centerMode: centerMode,
                prevArrow: '<button class="carousel__prev slick-prev"></button>',
                nextArrow: '<button class="carousel__next slick-next"></button>',
                dots: true,
                rows: 0,
                dotsClass: 'carousel__pager',
                slidesToShow: slidesToShow,
                infinite: true,
                variableWidth: variableWidth,
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: slidesToShowMobile,
                        }
                    },
                ]
            }
        );
    });
});