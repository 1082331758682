/**
 * Main
 *
 * The main file, page logic which is needed on all pages will be included here.
 * Please note for specific components like a slider create a separated components/<component_name>.js
 * The components will be loaded through the component loader and are not needed on every page!
 */

import 'jquery.mmenu';
import jQuery from 'jquery';

jQuery(function ($) {

    var $mega2 = $('nav li.mega-menu-2 a');
    var $mega1 = $('nav li.mega-menu a');

    $mega2.click(function () {
        $(this).next().toggleClass('open');
        $(this).toggleClass('js-arrow');

        $mega1.next().removeClass('open');
        $mega1.removeClass('js-arrow');
    });

    $mega1.click(function () {
        $mega2.next().removeClass('open');
        $mega2.removeClass('js-arrow');
    });

    $('#nav').mmenu(
        {
            scrollBugFix: true,
            offCanvas: {
                position: 'right'
            },
            navbar: {
                title: ''
            }
        }, {
            clone: true
        }
    );

    $('a[href="#top"]').on(
        'click', function (event) {
            event.preventDefault();

            $('html, body').animate({scrollTop: 0});
        }
    );

    $(window).scroll(function () {
        if ($(this).scrollTop() > 30) {
            $('.header').addClass('header--fixed');
        } else {
            $('.header').removeClass('header--fixed');
        }
    });

    var $mega = $('nav li.mega-menu a');

    $($mega).click(function () {
        $(this).next().toggleClass('open');
        $(this).toggleClass('js-arrow');
    });

    $(document).mouseup(function (e) {
        var container = $mega;

        // if the target of the click isn't the container nor a descendant of the container
        if (!container.is(e.target) && container.has(e.target).length === 0) {
            container.next().removeClass('open');
            container.removeClass('js-arrow');
        }
    });

    $('.products__list').find('nav').prev().addClass('js-space');
    $('.header__right nav > ul > li').find('ul').prev().addClass('menu-parent');
});
