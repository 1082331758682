/**
 * Brands
 *
 * This component requires jQuery and Slick to function.
 * You can require it on the page using <div data-component="brands"></div>
 */

import $ from 'jquery';
import 'slick-carousel';

$(function () {
    $('[data-component="brands"]').each(function () {
        var $holder = $(this);

        $holder.slick(
            {
                prevArrow: '<button class="front__brands-prev slick-prev"></button>',
                nextArrow: '<button class="front__brands-next slick-next"></button>',
                rows: 0,
                arrows: true,
                slidesToShow: 4,
                infinite: true,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            }
        );
    });
});