document.addEventListener('DOMContentLoaded', function () {
    function initializeScannerInput() {
        let buffer = '';

        document.addEventListener('keypress', function (event) {
            const key = event.key;

            if (key === 'Enter') {
                submitScanner(buffer);
                buffer = '';
            } else {
                buffer += key;
            }
        });
    }

    function submitScanner(scanned) {
        let url = parseScan(scanned);

        if (url !== false) {
            window.location.href = url;
        }
    }

    function parseScan(urlString) {
        let url;
        try {
            url = new URL(urlString);
        } catch (error) {
            if (error instanceof TypeError) {
                return false;
            }
            throw error;
        }

        if (url.hostname !== 'www.huis-en-wonen.nl') {
            return false;
        }

        return url;
    }

    initializeScannerInput();
});